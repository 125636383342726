import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ContentBuilder from '../components/ContentBuilder'
import SEO from '../components/Seo'

const Page = ({ data }) => {
  return (
    <Layout title={'4fb'}>
      <SEO title={data.sanityPages.slug.current} />
      {data.sanityPages.pageContent.map(content => ContentBuilder(content))}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query pageById($id: String) {
    sanityPages(_id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      pageContent {
        ... on SanityContentText {
          id
          _type
          isNoMarginBottom
          isNoMarginTop
          centered
          _rawText(resolveReferences: { maxDepth: 6 })
        }
        ... on SanityContentVideo {
          id
          _type
          videourl
          videoheight
        }
        ... on SanityContentImage {
          id
          _type
          image {
            asset {
              fluid(maxWidth: 2000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          textData {
            title
          }
        }
        ... on SanityImageAndText {
          id
          _type
          isLeftToRight
          isNoMarginBottom
          isNoMarginTop
          isRightToLeft
          _rawText(resolveReferences: { maxDepth: 6 })
          image {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanitySlideshow {
          id
          _type
          title
          slideshowimages {
            image {
              asset {
                fluid(maxWidth: 1000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityTextslideshow {
          id
          _type
          slideshowtexts {
            _rawText
          }
        }
        ... on SanityTile {
          id
          _type
          title
          pageContent {
            id
            title
            icon
            _rawText(resolveReferences: { maxDepth: 6 })
          }
        }
        ... on SanityProjectTeaser {
          id
          _type
          isNoMarginBottom
          isNoMarginTop
          pageContent {
            id
            slug {
              current
            }
            teaserData {
              subtitle
              logo {
                asset {
                  fixed(width: 200) {
                    ...GatsbySanityImageFixed
                  }
                }
              }
              preview {
                asset {
                  fluid(maxWidth: 800) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
